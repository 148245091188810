import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { Icon } from '#app/components/ui/icon'
import LanguageModal from '#app/features/localisation/language-modal'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

export function Footer() {
	let { t } = useTranslation('footer')

	return (
		<footer className=" m-0 mx-auto">
			<div className="flex flex-col justify-between text-black-90">
				<div className="bg-beige-50 bg-ampersandFooter bg-center bg-no-repeat md:bg-desktop-footer ">
					<div className="mx-auto flex flex-col justify-start gap-8 px-4 pt-14 md:flex-row md:justify-between md:space-x-8 md:px-[var(--plenty-padding)]">
						<div className=" flex flex-col gap-2">
							<div>
								<PlentyHeading as={'h7'}>Plenty&</PlentyHeading>
							</div>
							<div className="flex max-w-full flex-col gap-2 md:max-w-[300px]">
								<PlentyBody size={'md'}>{t('tagline')}</PlentyBody>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<PlentyHeading as={'h7'}>{t('about_us')}</PlentyHeading>
							</div>
							<div className="flex  flex-col gap-2">
								<Link to={'/about-us'} className="group w-fit cursor-pointer">
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('about_us')}
									</PlentyBody>
								</Link>
								<Link to={'/stores'} className="group w-fit cursor-pointer">
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('find_a_store')}
									</PlentyBody>
								</Link>
								<Link to={'/brands'} className="group w-fit cursor-pointer">
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('&_brands')}
									</PlentyBody>
								</Link>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<PlentyHeading as={'h7'}>{t('get_help')}</PlentyHeading>
							</div>
							<div className="flex  flex-col gap-2">
								<Link to={'/faq'} className="group w-fit cursor-pointer">
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('faq')}{' '}
									</PlentyBody>
								</Link>
								<Link
									to={'/click-and-collect-and-delivery'}
									className="group w-fit cursor-pointer"
								>
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('shipping_&_delivery')}
									</PlentyBody>
								</Link>
								<Link
									to={'/returns-and-refunds'}
									className="group w-fit cursor-pointer"
								>
									<PlentyBody
										size={'md'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										{t('returns_&_refunds')}
									</PlentyBody>
								</Link>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<PlentyHeading as={'h7'}>{t('follow_us')}</PlentyHeading>
							</div>
							<div className="flex  flex-col gap-2">
								<a
									href="https://www.facebook.com/profile.php?id=100089802061958"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="facebook" />
									<PlentyBody
										size="md"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										Facebook
									</PlentyBody>
								</a>
								<a
									href="https://www.instagram.com/plentyand_/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="instagram" />
									<PlentyBody
										size="md"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										Instagram
									</PlentyBody>
								</a>
								<a
									href="https://www.linkedin.com/company/plentyand/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="linkedin" />
									<PlentyBody
										size="md"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										LinkedIn
									</PlentyBody>
								</a>
								<a
									href="https://www.pinterest.dk/plentyand/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="pinterest" />
									<PlentyBody
										size="md"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									>
										Pinterest
									</PlentyBody>
								</a>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<PlentyHeading as={'h7'}>
									{t('contact', { ns: 'common' })}
								</PlentyHeading>
							</div>
							<div className="flex  flex-col gap-2">
								<PlentyBody size={'md'}>Plenty&</PlentyBody>
								<PlentyBody size={'md'}>Store Torv 1, 8000 Aarhus</PlentyBody>
								<PlentyBody size={'md'}>Support@plentyand.dk</PlentyBody>
								<PlentyBody size={'md'}>+45 82 38 25 50</PlentyBody>
							</div>
						</div>
					</div>
					<div className="flex justify-start space-x-2 px-4 pb-14 pt-8 md:flex-row md:justify-end md:space-x-8 md:px-[var(--plenty-padding)] md:pb-6 md:pt-4">
						<Icon className="text-body-md h-10 w-[60px]" name="mobile-pay" />
						<Icon className="text-body-md h-10 w-[60px]" name="debit-card" />
						<Icon className="text-body-md h-10 w-[60px]" name="visa" />
						<Icon className="text-body-md h-10 w-[60px]" name="master-card" />
					</div>
				</div>

				<div className="flex flex-col justify-between gap-4 px-4 py-4 md:flex-row md:px-[var(--plenty-padding)] md:py-4">
					<div className="md:justify-auto flex justify-between gap-6">
						<div className="hidden gap-2 md:block ">
							<LanguageModal />
						</div>
						<div>
							<PlentyBody size={'sm'}>Plenty&</PlentyBody>
						</div>
						<div>
							<PlentyBody
								size={'sm'}
							>{`© ${new Date().getFullYear()} All Rights Reserved`}</PlentyBody>
						</div>
					</div>
					<div className="flex justify-between md:gap-10">
						<div>
							<Link
								to="/policy/terms-and-condition"
								className="group w-fit cursor-pointer"
							>
								<PlentyBody
									size={'sm'}
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
								>
									{t('terms_&_conditions')}
								</PlentyBody>
							</Link>
						</div>
						<div>|</div>
						<div>
							<Link to="/policy/privacy" className="group w-fit cursor-pointer">
								<PlentyBody
									size={'sm'}
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
								>
									{t('privacy_policy')}
								</PlentyBody>
							</Link>
						</div>
						<div>|</div>
						<div>
							<Link
								to={'/policy/cookie'}
								className="group w-fit cursor-pointer"
							>
								<PlentyBody
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									size={'sm'}
								>
									Cookies
								</PlentyBody>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
